
import permissions from '~/mixins/permissions'

export default {
  name: 'LayoutDefault',
  mixins: [permissions],
  middleware: 'isAdmin',
  head: vm => ({
    bodyAttrs: {
      class: 'has-sidebar'
    },
    style: [
      vm.$auth.user?.current_company?.bg_color
        ? {
            hid: 'custom-style',
            cssText: `
            :root {
            --main-bg-color: ${vm.$auth.user.current_company.bg_color} !important;
            }
          `,
            type: 'text/css'
          }
        : undefined
    ].filter(Boolean)
  })
}
