import Vue from 'vue'

Vue.filter('minutes', function (count) {
  if (count === 1) {
    return `${count} minuta`
  } else if (count >= 2 && count <= 4) {
    return `${count} minuty`
  } else {
    return `${count} minut`
  }
})

Vue.filter('seconds', function (count) {
  if (count === 1) {
    return `${count} vteřina`
  } else if (count >= 2 && count <= 4) {
    return `${count} vteřiny`
  } else {
    return `${count} vteřin`
  }
})

Vue.filter('points', function (count) {
  if (count === 1) {
    return `${count} bod`
  } else if (count >= 2 && count <= 4) {
    return `${count} body`
  } else {
    return `${count} bodů`
  }
})

Vue.filter('attempts', function (count) {
  if (count === 1) {
    return `${count} pokus`
  } else if (count >= 2 && count <= 4) {
    return `${count} pokusy`
  } else {
    return `${count} pokusů`
  }
})

Vue.filter('questions', function (count) {
  if (count === 1) {
    return `${count} otázka`
  } else if (count >= 2 && count <= 4) {
    return `${count} otázky`
  } else {
    return `${count} otázek`
  }
})
