
import permissions from '~/mixins/permissions'

export default {
  name: 'PageError',
  mixins: [permissions],
  layout: 'auth',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  head () {
    const title = this.error && this.error.statusCode
      ? `Chyba ${this.error.statusCode}`
      : 'Chyba'

    return {
      title,
      bodyAttrs: {
        id: 'error-page'
      }
    }
  }
}
