
import permissions from '~/mixins/permissions'
import bgColor from '~/mixins/bgColor'

export default {
  name: 'LayoutStudent',
  mixins: [permissions, bgColor],
  middleware: 'isStudent',
  methods: {
    async logout () {
      await this.$auth.logout()
      await this.$router.push('/')
      this.bgColor = null
    }
  }
}
