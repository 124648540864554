export default {
  watch: {
    '$auth.loggedIn' () {
      this.setPermissions()
    }
  },
  async created () {
    await this.setPermissions()
  },
  methods: {
    async setPermissions () {
      const { permissions = [], roles = [] } = this.$auth.loggedIn ? this.$auth.user : {}
      await this.$laravel.setPermissions(permissions)
      await this.$laravel.setRoles(roles)
    }
  }
}
